<div
    *ngIf="remoteUser && (!remoteUser.isMicActive || !remoteUser.isCameraActive)"
    class="absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] flex flex-row gap-4 z-30 select-none"
>
    <!---- MIC NOT ACTIVE ---->
    <app-host-tools-off
        [remoteUsers]="agoraService.remoteUsers.value"
        [isPip]="isPip"
        [toolType]="HostTools.MIC"
        *ngIf="!remoteUser.isMicActive"
    />

    <app-host-tools-off
        [remoteUsers]="agoraService.remoteUsers.value"
        [isPip]="isPip"
        [toolType]="HostTools.CAMERA"
        *ngIf="!remoteUser.isCameraActive"
    />

    <!---- CAM NOT ACTIVE ---->
</div>
