<div *ngIf="host" class="absolute top-6 left-6 z-50">
    <div
        class="flex flex-row grow w-full min-w-40 h-10 p-1 justify-between gap-2 items-center bg-[#3F424A] bg-opacity-40 rounded-full select-none"
    >
        <div class="flex flex-row gap-2 items-center justify-center">
            <img src="{{ host.photo }}" alt="Profile Photo" class="rounded-full size-8 object-cover" />
            <div class="flex flex-col">
                <p class="text-sm text-white font-semibold">{{ host.fullName }}</p>
                <div class="flex flex-row items-center gap-1/2">
                    <img src="assets/images/shared/crystals.svg" alt="Crystals" class="size-4" />
                    <p class="text-[10px] text-white">{{ convertCoin(host.earnedCoin) }}</p>
                </div>
            </div>
        </div>
        <button *ngIf="!host.isFollowing" (click)="followStreamer(host.id)">
            <app-follow-button></app-follow-button>
        </button>
    </div>
</div>
