import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {PartyParticipantsResponse} from '@core/models/chat/party-participants-response';
import {VideoTokenResponse} from '@core/models/chat/video-token-response';
import {environment} from '@env/environment';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    http = inject(HttpClient);

    private chatBaseUrl: string;
    private agoraVideoTokenUrl: string;
    private agoraRenewVideoTokenUrl: string;
    private participantsUrl: string;

    partyFeatureGoalCurrent$ = new BehaviorSubject<number>(0);

    constructor() {
        this.chatBaseUrl = environment.apiUrl + '/chat';
        this.agoraVideoTokenUrl = this.chatBaseUrl + '/generateVideoToken';
        this.agoraRenewVideoTokenUrl = this.chatBaseUrl + '/renewAgoraToken';
        this.participantsUrl = this.chatBaseUrl + '/participants';
    }

    updatePartyFeatureGoalCurrent(value: number) {
        this.partyFeatureGoalCurrent$.next(value);
    }

    generateVideoToken(roomId: number): Observable<VideoTokenResponse> {
        const reqBody = {
            roomId: roomId,
        };
        return this.http.post<VideoTokenResponse>(this.agoraVideoTokenUrl, reqBody);
    }

    renewVideoToken(roomId: number): Observable<VideoTokenResponse> {
        const reqBody = {
            roomId: roomId,
        };
        return this.http.post<VideoTokenResponse>(this.agoraRenewVideoTokenUrl, reqBody);
    }

    getPartyParticipants(roomId: number): Observable<PartyParticipantsResponse> {
        const reqParams = {
            roomId: roomId,
        };

        const options = {params: reqParams};
        return this.http.get<PartyParticipantsResponse>(this.participantsUrl, options);
    }
}
