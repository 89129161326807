<div
    class="max-w-20 max-h-15 relative transition-transform duration-1000 ease-in-out select-none"
    [style.transform]="'translate(' + positions.xPosition + 'px, ' + positions.yPosition + 'px)'"
    [style.left]="this.leftOffset + 'px'"
>
    <!--- USER PROFILE BORDER --->
    <div class="absolute left-7 top-[-28px] z-50">
        <div class="flex flex-row mb-1 items-center">
            <img src="assets/images/shared/crystals.svg" class="w-2 h-2" alt="coins" />
            <p class="text-[10px] font-normal text-white ml-1">{{ userCoins }}</p>
        </div>
        <div class="size-8 rounded-full border" [style.border-color]="borderColor">
            <img src="{{ userPhoto }}" class="rounded-full w-full h-full" alt="user profile" />
        </div>
    </div>

    <div class="w-full h-full absolute">
        <ng-lottie width="80px" height="60px" (animationCreated)="onAnimationCreated()" [options]="options"></ng-lottie>
    </div>
</div>
