import {Injectable} from '@angular/core';
import {AgoraVideoContainerComponent} from '@shared/components/live-stream/agora-video-container/agora-video-container.component';

@Injectable({
    providedIn: 'root',
})
export class AgoraVideoService {
    private videoComponent!: AgoraVideoContainerComponent;

    setComponent(component: AgoraVideoContainerComponent) {
        this.videoComponent = component;
    }

    callHandleWindowFocused() {
        if (this.videoComponent) {
            this.videoComponent.handleWindowFocused();
        }
    }
}
