export enum IncomingPartyInfoCommandEvent {
    PARTICIPANT_COUNT = 'participantCount',
    EARNED_COINS = 'earnedCoins',
    BEST_SUPPORTER = 'bestSupporter',
    BEST_SUPPORTERS = 'bestSupporters',
    KEEP_USER_IN_PRO_PARTY = 'keepUserInProParty',
    MUTE_PARTICIPANT = 'muteParticipant',
    UN_MUTE_PARTICIPANT = 'unmuteParticipant',
    HOST_CONNECTION_STATE = 'hostConnectionState',
    TECHNICAL_INFO = 'technicalInfo',
    KICK_PARTICIPANT = 'kickParticipant',
    GIFT_BROADCAST = 'giftBroadcast',
}
