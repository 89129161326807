<div class="relative p-4 w-full max-w-md max-h-full mx-auto">
    <app-mt-modal-close-button />
    <div class="relative flex items-center justify-center flex-col bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex flex-col items-center justify-center gap-10 px-4 py-8 md:pt-10 md:pb-8 select-none">
            <div class="flex items-center justify-center w-20 h-20 bg-[#fe2e6c] bg-opacity-30 rounded-full p-4">
                <img src="assets/images/shared/icons/video-off-red.svg" alt="Video Off" class="w-20 h-20" />
            </div>

            <h2 class="text-2xl font-bold text-center text-black dark:text-gray-100">Banlandın</h2>

            <p class="text-center text-sm font-normal text-gray-800 dark:text-gray-100 max-w-[80%]">
                Cikcik yayın kurallarına uymadığın için yayından kalıcı olarak banlandın.
            </p>

            <button
                (click)="routeToHome()"
                class="flex items-center justify-center w-full text-lg font-semibold bg-black text-white rounded-lg h-12 md:hover:bg-opacity-80 transition-opacity duration-200 ease-in-out"
            >
                Devam et
            </button>
        </div>
    </div>
</div>
