import {Component, Input, OnInit} from '@angular/core';
import {HostTools} from '@core/components/ui-components/stream/host-tools-off/HostTools';
import {NgClass} from '@angular/common';
import {RtcDataUser} from '@core/models/agora/rtc-data';

@Component({
    selector: 'app-host-tools-off',
    standalone: true,
    imports: [NgClass],
    templateUrl: './host-tools-off.component.html',
    styleUrl: './host-tools-off.component.scss',
})
export class HostToolsOffComponent implements OnInit {
    @Input() toolType!: HostTools;
    @Input() isPip!: boolean;
    @Input() remoteUsers!: RtcDataUser[];
    icon!: string;
    ngOnInit() {
        switch (this.toolType) {
            case HostTools.CAMERA:
                this.icon = 'video-off';
                break;
            case HostTools.MIC:
                this.icon = 'mic-off';
                break;
            default:
                this.icon = 'camera-off';
        }
    }
}
