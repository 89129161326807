import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    inject,
    ChangeDetectorRef,
    ViewContainerRef,
    ViewChild,
    Output,
    EventEmitter,
    Type,
} from '@angular/core';
import {Router} from '@angular/router';
import {VideoTokenResponse} from '@core/models/chat/video-token-response';
import {AgoraService} from '@core/services/agora/agora.service';
import {ChatService} from '@core/services/chat/chat.service';
import {IAgoraRTCClient, IAgoraRTCRemoteUser} from 'agora-rtc-sdk-ng';
import {CandidateService} from '@core/services/candidate/candidate.service';
import {CandidateDetailResponse} from '@core/models/candidate/candidate-detail-response';
import {HostProfileComponent} from '@shared/components/live-stream/host-profile/host-profile.component';
import {HostToolsContainerComponent} from '@shared/components/live-stream/host-tools-container/host-tools-container.component';
import {AgoraVideoService} from '@core/services/agora-video-service/agora-video-service.service';
import {ButtonLoadingComponent} from '@core/components/ui-components/button-loading/button-loading.component';
import {VideLoadingComponent} from '@core/components/ui-components/vide-loading/vide-loading.component';
import {Room} from '@core/models/candidate/room';

export interface VideoContainers {
    userId: number;
    container: HTMLElement;
}

@Component({
    selector: 'app-agora-video-container',
    standalone: true,
    imports: [],
    templateUrl: './agora-video-container.component.html',
    styleUrl: './agora-video-container.component.scss',
})
export class AgoraVideoContainerComponent implements OnInit, OnDestroy {
    @Input() token!: string;
    @Input() room!: string;
    @Input() uid!: number;
    @Input() roomId!: number;
    @Input() isFirstInitialize!: boolean;
    @Input() stream!: AgoraService;
    @Input() isPip = false;
    @Input() hostId!: number;
    @Input() isMobile = false;
    @Output() videoPublished = new EventEmitter<void>();
    streamUser!: IAgoraRTCRemoteUser;
    @ViewChild('dynamicContainer', {read: ViewContainerRef, static: true})
    dynamicContainer!: ViewContainerRef;

    videoService = inject(AgoraVideoService);

    router = inject(Router);
    chatService = inject(ChatService);
    candidateService = inject(CandidateService);
    videoContainers!: VideoContainers[];
    videoPlayers = 0;
    remotePlayerContainer!: HTMLElement;
    constructor(private cdr: ChangeDetectorRef) {
        this.videoContainers = [];
    }

    ngOnInit(): void {
        this.videoService.setComponent(this);
        this.presentAgoraView(this.token, this.room, this.uid, this.roomId);
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnDestroy(): void {}
    injectComponent<T>(parentElement: HTMLElement, component: Type<T>) {
        const componentRef = this.dynamicContainer.createComponent(component);

        parentElement.appendChild(componentRef.location.nativeElement);
        return componentRef;
    }
    presentAgoraView(_token: string, room: string, _uid: number, roomId: number) {
        this.remotePlayerContainer = document.getElementById('video-container')!;

        this.remotePlayerContainer!.style.height = '100%';
        this.remotePlayerContainer!.style.width = '100%';

        // createdElement!.style.width = '100%';
        // createdElement!.style.height = '100%';
        // createdElement!.style.position = 'relative';
        // createdElement!.style.marginLeft = 'auto';
        // createdElement!.style.marginRight = 'auto';
        //
        // data.remoteUserVideoTrack?.play(createdElement!);
        // data.remoteUserAudioTrack?.play();

        if (this.stream.rtcClient) {
            this.stream.rtcClient.on('connection-state-change', async (_curState, _revState, _reason) => {
                if (_curState === 'DISCONNECTED' && _reason === 'UID_BANNED') {
                    this.stream.isDisconnected = true;
                }
            });
            this.stream.rtcClient.on('user-joined', async _user => {
                const createdElement = document.createElement('div');
                createdElement.innerHTML = '';
                const users = this.stream.remoteUsers.value;
                if (this.remotePlayerContainer) {
                    this.remotePlayerContainer?.appendChild(createdElement);
                    this.videoPlayers++;

                    const isHost = _user.uid == this.hostId;
                    if (isHost) {
                        this.remotePlayerContainer?.prepend(createdElement);
                    }

                    this.videoPlayers > 1 && (this.remotePlayerContainer.style.width = '100%');

                    createdElement.style.width = '100%';
                    createdElement.style.height = '100%';

                    if (!this.isPip) {
                        switch (this.videoPlayers) {
                            case 1:
                                this.remotePlayerContainer.classList.remove('three-video-layout');
                                break;
                            case 2:
                                this.remotePlayerContainer.classList.add('grid', 'grid-cols-2', 'gap-1');
                                if (isHost) {
                                    this.videoContainers[0].container.style.height = '90%';
                                    this.videoContainers[0].container.style.width = '90%';
                                    this.videoContainers[0].container.style.top = '5%';
                                } else {
                                    createdElement!.style.height = '90%';
                                    createdElement!.style.width = '90%';
                                    createdElement!.style.top = '5%';
                                }
                                break;
                            case 3:
                                this.remotePlayerContainer.classList.add('grid-rows-2');

                                if (isHost) {
                                    createdElement.style.gridArea = '1 / 1 / 3 / 2';
                                } else {
                                    createdElement!.style.height = '90%';
                                    createdElement!.style.width = '90%';
                                    createdElement!.style.bottom = '5%';
                                    const hostContainer = this.videoContainers.find(
                                        container => container.userId === this.hostId,
                                    );
                                    hostContainer!.container.style.gridArea = '1 / 1 / 3 / 2';
                                }

                                break;
                            case 4:
                                if (isHost) {
                                    createdElement.style.gridArea = '';
                                } else {
                                    const hostContainer = this.videoContainers.find(
                                        container => container.userId === this.hostId,
                                    );
                                    hostContainer!.container.style.gridArea = '';
                                }

                                break;
                        }
                    } else {
                        createdElement.style.width = '100%';
                        createdElement.style.height = '100%';
                        this.remotePlayerContainer.classList.add('grid', 'grid-cols-2');
                        switch (this.videoPlayers) {
                            case 1:
                                this.remotePlayerContainer.classList.remove('grid-cols-2');
                                break;
                        }
                    }
                }
                // data.remoteUserVideoTrack = user.videoTrack;
                // data.remoteUserAudioTrack = user.audioTrack;
                // data.remoteUid = user.uid.toString();
                createdElement!.id = _user.uid.toString() + '-video';
                // data.remoteUid = user.uid.toString();

                createdElement!.style.position = 'relative';
                createdElement.classList.add('bg-black', 'bg-opacity-30', 'drop-shadow-2xl');

                users.push({
                    userId: _user.uid as number,
                    data: _user,
                    isMuted: false,
                    isMicActive: true,
                    isCameraActive: true,
                    isVideoPublished: false,
                    isAudioPublished: false,
                });
                this.stream.remoteUsers.next(users);
                const hostToolComponent = this.injectComponent(createdElement, HostToolsContainerComponent);
                hostToolComponent.instance.userId = _user.uid as number;
                hostToolComponent.instance.agoraService = this.stream;
                hostToolComponent.instance.isPip = this.isPip;
                const videoLoading = this.injectComponent(createdElement, VideLoadingComponent);
                videoLoading.instance.divId = `${_user.uid}-video-loading`;
                this.videoContainers.push({userId: _user.uid as number, container: createdElement});
                this.videoContainers.sort(a => (a.userId === this.hostId ? -1 : 1));
                setTimeout(() => {
                    const user = this.stream.remoteUsers.value.find(_u => _u.userId === _user.uid);
                    if (user) {
                        user.isCameraActive = user.isVideoPublished;
                        user.isMicActive = user.isAudioPublished;
                        this.stream.updateRemoteUser(_user.uid as number, user);
                        if (!videoLoading.instance.isRemove) {
                            createdElement.removeChild(videoLoading.location.nativeElement);
                            videoLoading.instance.isRemove = true;
                        }
                    }
                }, 500);

                this.candidateService.getCandidateDetail(_user.uid as number).subscribe({
                    next: (data: CandidateDetailResponse) => {
                        if (data.candidate) {
                            const candidateData = data.candidate;
                            const user = this.stream.remoteUsers.value.find(_u => _u.userId === _user.uid);
                            if (user) {
                                this.stream.remoteUsers.value.splice(this.stream.remoteUsers.value.indexOf(user), 1);
                                user.room = candidateData;
                                this.stream.remoteUsers.value.push(user);
                                this.stream.remoteUsers.next(this.stream.remoteUsers.value);
                            }
                            if (!this.isPip) {
                                let shouldInject = true;
                                if (this.isMobile) {
                                    if ((_user.uid as number) === this.hostId) {
                                        shouldInject = false;
                                    }
                                }
                                if (shouldInject) {
                                    const crf = this.injectComponent(createdElement, HostProfileComponent);
                                    crf.instance.host = data.candidate;
                                }
                            }
                            if (data.candidate.roomId) {
                                const containerGiftAnimationDiv = this.createGiftAnimationContainer();
                                containerGiftAnimationDiv.id = data.candidate.roomId.toString() + '-gift-animation';
                                createdElement!.appendChild(containerGiftAnimationDiv);
                            }
                        }
                    },
                });
                this.cdr.detectChanges();

                this.stream.rtcClient.on('user-published', async (user, mediaType) => {
                    //this.stream.rtcClientList.get(room)!.client?.enableDualStream();
                    //this.stream.rtcClientList.get(room)!.client?.setLowStreamParameter({ width: { min: 120, max:180}, height:{min:180, max:240}});
                    await this.stream.rtcClient.subscribe(user, mediaType);
                    const remoteUser = this.stream.remoteUsers.value.find(_user => _user.userId === user.uid);
                    if (mediaType == 'video') {
                        // data.remoteUserVideoTrack?.play(createdElement!);
                        createdElement.classList.remove('bg-black', 'bg-opacity-30');
                        if (!videoLoading.instance.isRemove) {
                            createdElement.removeChild(videoLoading.location.nativeElement);
                            videoLoading.instance.isRemove = true;
                        }
                        user.videoTrack?.play(createdElement!);
                        if (remoteUser) {
                            remoteUser.isCameraActive = true;
                            remoteUser.isVideoPublished = true;
                            remoteUser.data = user;
                            this.stream.updateRemoteUser(user.uid as number, remoteUser);
                        }
                        this.onVideoPublished();
                        this.cdr.detectChanges();
                    }

                    if (mediaType == 'audio') {
                        // data.remoteUserAudioTrack = user.audioTrack;
                        if (remoteUser) {
                            remoteUser.isMicActive = true;
                            remoteUser.isAudioPublished = true;
                            remoteUser.data = user;
                            if (!videoLoading.instance.isRemove) {
                                createdElement.removeChild(videoLoading.location.nativeElement);
                                videoLoading.instance.isRemove = true;
                            }
                            this.stream.updateRemoteUser(user.uid as number, remoteUser);
                        }
                        if (!this.stream.remoteUsers.value.find(_user => _user.userId === user.uid)?.isMuted) {
                            // data.remoteUserAudioTrack?.setVolume(60);
                            // data.remoteUserAudioTrack?.play();
                            user.audioTrack?.setVolume(60);
                            user.audioTrack?.play();
                        }
                    }

                    // if (!this.stream.remoteUsers.value.find(_user => _user.userId === user.uid)?.isMuted) {
                    //     // data.remoteUserAudioTrack?.setVolume(60);
                    //     // data.remoteUserAudioTrack?.play();
                    //     user.audioTrack?.setVolume(60);
                    //     user.audioTrack?.play();
                    // }

                    this.stream.rtcClient.on('user-unpublished', async (_user, _mediaType) => {
                        /*if (_mediaType === 'video') {
                if (data.client) {
                    this.clearLiveStreamVideos();
                    this.router.navigate(['/live']);
                }
            }*/ const remoteUser = this.stream.remoteUsers.value.find(_user => _user.userId === user.uid);

                        if (_mediaType === 'video') {
                            if (remoteUser) {
                                remoteUser.isCameraActive = false;
                                this.stream.remoteUsers.next(this.stream.remoteUsers.value);
                            }
                        }
                        if (_mediaType === 'audio') {
                            if (remoteUser) {
                                remoteUser.isMicActive = false;
                                this.stream.remoteUsers.next(this.stream.remoteUsers.value);
                            }
                        }

                        if (_mediaType === 'datachannel') {
                            if (this.stream.rtcClient) {
                                this.clearLiveStreamVideos();
                                this.router.navigate(['/live']);
                            }
                        }
                    });

                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    this.stream.rtcClient.on('connection-state-change', async (_curState, _revState, _reason) => {
                    });

                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    this.stream.rtcClient.on('user-left', async (_user, _reason) => {
                        const container = this.videoContainers.find(_container => _container.userId === _user.uid);
                        if (container) {
                            container.container.innerHTML = '';
                            this.videoPlayers--;
                            const users = this.stream.remoteUsers.value;
                            users.splice(
                                users.findIndex(u => u.userId === _user.uid),
                                1,
                            );
                            this.stream.remoteUsers.next(users);
                            if (!this.isPip) {
                                switch (this.videoPlayers) {
                                    case 1:
                                        this.remotePlayerContainer.classList.remove('three-video-layout');
                                        break;
                                    case 2:
                                        this.videoContainers[1].container.style.height = '90%';
                                        this.videoContainers[1].container.style.width = '90%';
                                        this.videoContainers[1].container.style.top = '5%';
                                        this.remotePlayerContainer.classList.remove('grid-rows-2');
                                        break;
                                    case 3:
                                        this.remotePlayerContainer.classList.add('grid-rows-2');
                                        this.videoContainers[2]!.container.style.height = '90%';
                                        this.videoContainers[2]!.container.style.width = '90%';
                                        this.videoContainers[2]!.container.style.bottom = '5%';
                                        this.videoContainers[0].container.style.gridArea = '1 / 1 / 3 / 2';
                                        break;
                                }
                            }

                            this.videoContainers.splice(this.videoContainers.indexOf(container), 1);
                            this.remotePlayerContainer.removeChild(container.container);
                        }
                    });

                    this.stream.rtcClient.on('token-privilege-will-expire', async () => {
                        if (this.stream.rtcClientList.has(room)) {
                            this.getRenewToken(roomId!, this.stream.rtcClient);
                        }
                    });
                });
            });
        }
    }

    onVideoPublished() {
        this.videoPublished.emit();
    }

    clearLiveStreamVideos() {
        const streamView = document.getElementById('video-container');
        //data.client?.leave();
        streamView?.remove();
    }

    createGiftAnimationContainer() {
        const containerGiftAnimationDiv = document.createElement('div');
        containerGiftAnimationDiv.classList.add(
            'containerGiftAnimation',
            'h-full',
            'w-full',
            'absolute',
            'top-0',
            'left-0',
            'z-40',
        );
        return containerGiftAnimationDiv;
    }

    handleWindowFocused() {
        this.resetVideoPlayers();
        this.presentAgoraView(this.token, this.room, this.uid, this.roomId);
    }

    resetVideoPlayers() {
        this.videoPlayers = 0;
        this.videoContainers = [];
        this.remotePlayerContainer.innerHTML = '';
        this.stream.clearRemoteUsers();
    }

    getRenewToken(roomId: number, client: IAgoraRTCClient) {
        this.chatService.renewVideoToken(roomId).subscribe({
            next: (data: VideoTokenResponse) => {
                if (data.success) {
                    if (data.videoToken != null) {
                        client.renewToken(data.videoToken);
                    }
                }
            },
        });
    }
}
