import {Component} from '@angular/core';

@Component({
    selector: 'app-vide-loading',
    standalone: true,
    imports: [],
    templateUrl: './vide-loading.component.html',
    styleUrl: './vide-loading.component.scss',
})
export class VideLoadingComponent {
    divId!: string;
    isRemove = false;
}
