import {CarTypes} from '@shared/components/live-stream/party-feature/race/CarTypes';

export default function CarColorToName(carColor: CarTypes) {
    switch (carColor) {
        case CarTypes.RED:
            return 'red_car';
        case CarTypes.BLUE:
            return 'blue_car';
        case CarTypes.GREEN:
            return 'green_car';
        case CarTypes.YELLOW:
            return 'yellow_car';
        case CarTypes.GHOST:
            return 'ghost';
    }
}
