import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {CarComponent} from '@shared/components/live-stream/party-feature/race/car/car.component';
import {RaceData} from '@shared/components/live-stream/party-feature/race/race-data';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';
import {WinnerComponent} from '@shared/components/live-stream/party-feature/race/winner/winner.component';
import {PartyFeatureRaceRoadComponent} from '@shared/components/live-stream/party-feature/race/party-feature-race-road/party-feature-race-road.component';
import {RaceDetail} from '@core/models/stream-feature/race-detail';
import {PartyStickerState} from '@core/enums/party/party-sticker-state.enum';
import {interval, Observable, Subscription} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {PartyUserSocket} from '@core/models/chat/socket/party-user-socket';
import AVideoPlayer from '@core/lib/alpha-video-player/player';
import {AuthService} from '@core/services';
import userPhotoConverter from '@core/utils/helpers/userPhotoConverter';

@Component({
    selector: 'app-race',
    standalone: true,
    imports: [CarComponent, NgClass, NgForOf, LottieComponent, WinnerComponent, PartyFeatureRaceRoadComponent, NgIf],
    templateUrl: './race.component.html',
    styleUrl: './race.component.scss',
})
export class RaceComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    raceData: RaceData[] = [];
    options: AnimationOptions = {
        path: 'assets/lotties/race-assets/winner.json',
    };
    maxWidth!: number;
    xOffset: number = 0;
    roadWidth!: number;
    rightOffset: number = 100;
    timer!: Observable<number>;
    timerSubscription!: Subscription;
    remainingSeconds!: number;
    timerText!: string;
    raceFinished: boolean = false;
    showWinner: boolean = false;
    showDraw: boolean = false;
    alphaVideoPlayer!: AVideoPlayer;
    myUserId!: number;
    @Input() raceDetail!: RaceDetail;
    @Input() participantList: PartyUserSocket[] = [];
    @ViewChild('roadContainer') roadContainer!: ElementRef;
    @Input() sizeChanged!: boolean;
    @Output() raceFinishedEvent = new EventEmitter<null>();

    winner!: {name: string; photo: string};
    constructor(
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.myUserId = this.authService.userId;

        this.startTimer();
        this.updateData();
    }

    ngOnDestroy() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
        this.maxWidth = this.roadContainer.nativeElement.offsetWidth;
        this.xOffset = this.maxWidth * (15 / 100);
        this.roadWidth = this.maxWidth - this.xOffset - this.rightOffset;
    }
    ngOnChanges(changes: SimpleChanges) {
        if ('raceDetail' in changes) {
            if (this.raceDetail.state != PartyStickerState.DELETED) this.updateData();

            if (this.raceDetail.state == PartyStickerState.DELETED) {
                this.timerSubscription.unsubscribe();
                if (this.raceDetail.top3.length > 0) {
                    if (this.raceDetail.top3.length > 1) {
                        if (this.raceDetail.top3[0].p == this.raceDetail.top3[1].p) {
                            this.prepareDrawAnim();
                            this.raceFinished = true;
                            return;
                        }
                    }
                    this.prepareWinnerAnim();
                } else this.onWinnerAnimationCompleted();
                this.raceFinished = true;
            }
        }

        if ('sizeChanged' in changes) {
            if (this.sizeChanged) {
                this.cdr.detectChanges();
                this.onResize();
            }
        }
    }

    prepareWinnerAnim() {
        const winnerUserId = this.raceData[0].sId;
        const winnerUser = this.participantList.find(user => user.id === winnerUserId);
        if (winnerUser) {
            const winnerUserPhoto = winnerUser.photo;

            let photoUrl: string = winnerUserPhoto;
            if (!winnerUserPhoto.includes('defaultMan'))
                photoUrl = winnerUserPhoto.substring(winnerUserPhoto.lastIndexOf('/') + 1);
            this.winner = {name: winnerUser.fullName, photo: photoUrl};
        }
        this.showWinner = true;
    }

    prepareDrawAnim() {
        this.showDraw = true;
        setTimeout(() => {
            const containerElement = document.querySelector('.drawAnimationContainer') as HTMLElement;
            this.alphaVideoPlayer = new AVideoPlayer({
                src: 'assets/lotties/race-assets/draw_race.mp4',
                speed: 1,
                loop: false,
                autoplay: false,
                muted: false,
                volume: 0.1,
                container: containerElement,
                orientation: 'landscape',
                stencilOrder: 'back',
                onEnded: this.onDrawAnimationCompleted,
                onError() {
                    console.log('animation error');
                },
                canPlay: () => {
                    this.alphaVideoPlayer.play();
                },
            });
        }, 500);
    }
    onDrawAnimationCompleted() {
        document.querySelector('.drawAnimationContainer')!.innerHTML = '';

        this.alphaVideoPlayer.destroy();
        this.showDraw = false;
        this.onWinnerAnimationCompleted();
    }

    onWinnerAnimationCompleted() {
        this.raceFinishedEvent.emit();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.raceFinished) return;
        this.maxWidth = this.roadContainer.nativeElement.offsetWidth;
        this.xOffset = this.maxWidth * (15 / 100);
        this.roadWidth = this.maxWidth - this.xOffset - this.rightOffset;
        const positions = this.calculatePosition();
        for (const data of this.raceData) {
            const userPosition = positions.find(position => position.sId === data.sId);
            if (userPosition) {
                data.xPosition = userPosition.xPosition;
                data.yPosition = userPosition.yPosition;
            }
        }
    }

    startTimer() {
        this.remainingSeconds = this.raceDetail.timeLeft;

        const timer$ = interval(1000).pipe(takeWhile(() => this.remainingSeconds > 0));

        this.timerSubscription = timer$.subscribe(() => {
            this.remainingSeconds--;
            this.timerText = this.secondsToMMSS(this.remainingSeconds);
        });
    }

    secondsToMMSS(seconds: number) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    updateData() {
        this.raceData = this.raceDetail.top3;
        if (this.myUserId === undefined) return;
        const myUserIndex = this.raceData.findIndex(data => data.sId === this.myUserId);
        if (myUserIndex === -1) {
            const ghostUser = {sId: this.myUserId, cId: 0, p: 0};
            this.raceData.push(ghostUser);
        }
        if (myUserIndex !== -1) {
            const oldIndex = this.raceData.findIndex(data => data.sId === this.myUserId && data.cId === 0);
            if (oldIndex !== -1) {
                this.raceData.splice(oldIndex, 1);
            }
        }
        const ghostCars = this.raceData.filter(data => data.cId === 0);
        if (ghostCars.length > 1) {
            this.raceData.splice(
                this.raceData.findIndex(data => data.sId == undefined),
                1,
            );
        }
        const positions = this.calculatePosition();
        for (const data of this.raceData) {
            let userPosition = positions.find(position => position.sId === data.sId);
            if (data.cId === 0) userPosition = {xPosition: 0, yPosition: 5, sId: data.sId};
            if (userPosition) {
                data.xPosition = userPosition.xPosition;
                data.yPosition = userPosition.yPosition;
            }
            const user = this.participantList.find(user => user.id === data.sId);
            if (user) {
                if (user.photo == '' || user.photo == null) {
                    user.photo = userPhotoConverter(user.id);
                }
                data.userPhoto = user.photo;
            } else {
                data.userPhoto = userPhotoConverter(this.myUserId);
            }
        }
    }

    calculatePosition() {
        let totalPoint = 0;
        for (const data of this.raceData) totalPoint += data.p;
        let pixelPerPoint: number;

        if (totalPoint != 0) {
            pixelPerPoint = this.roadWidth / totalPoint;
        } else {
            pixelPerPoint = 0;
        }
        if (this.raceData.length === 1) pixelPerPoint = pixelPerPoint / 2;

        const positions: {xPosition: number; yPosition: number; sId: number}[] = [];
        this.raceData.forEach((value, index) => {
            const position = value.p * pixelPerPoint;
            const userPosition = {xPosition: position, sId: value.sId, yPosition: index * 5 + 5};
            positions.push(userPosition);
        });

        return positions;
    }
    trackById(index: number, item: RaceData): number {
        return item.sId; // Assuming sId is unique for each item
    }
}
