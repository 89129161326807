import {Component} from '@angular/core';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';

@Component({
    selector: 'app-party-feature-race-road',
    standalone: true,
    imports: [LottieComponent],
    templateUrl: './party-feature-race-road.component.html',
    styleUrl: './party-feature-race-road.component.scss',
})
export class PartyFeatureRaceRoadComponent {
    options: AnimationOptions = {
        path: 'assets/lotties/race-assets/road.json',
    };
}
