import {Component, OnInit} from '@angular/core';
import {HostToolsOffComponent} from '@core/components/ui-components/stream/host-tools-off/host-tools-off.component';
import {NgIf} from '@angular/common';
import {HostTools} from '@core/components/ui-components/stream/host-tools-off/HostTools';
import {AgoraService} from '@core/services/agora/agora.service';
import {RtcData, RtcDataUser} from '@core/models/agora/rtc-data';

@Component({
    selector: 'app-host-tools-container',
    standalone: true,
    imports: [HostToolsOffComponent, NgIf],
    templateUrl: './host-tools-container.component.html',
    styleUrl: './host-tools-container.component.scss',
})
export class HostToolsContainerComponent implements OnInit {
    agoraService!: AgoraService;
    userId: number = 0;
    remoteUser!: RtcDataUser;
    isPip: boolean = false;
    protected readonly HostTools = HostTools;

    ngOnInit() {
        this.agoraService.remoteUsers.subscribe(() => {
            const remoteUser = this.agoraService.remoteUsers.value.find(user => user.userId === this.userId);
            if (remoteUser) {
                this.remoteUser = remoteUser;
            }
        });
    }
}
