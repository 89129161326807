<div
    class="rounded-full flex items-center justify-center bg-[#3F424A] bg-opacity-40"
    [ngClass]="isPip && remoteUsers.length > 1 ? 'size-10' : 'size-20'"
>
    <img
        src="assets/images/shared/icons/{{ icon }}.svg"
        alt="Video Off"
        [ngClass]="isPip && remoteUsers.length > 1 ? 'size-5' : 'size-10'"
    />
</div>
