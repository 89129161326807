import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';

@Component({
    selector: 'app-party-feature-race-winner',
    standalone: true,
    imports: [LottieComponent],
    templateUrl: './winner.component.html',
    styleUrl: './winner.component.scss',
})
export class WinnerComponent implements OnInit {
    @Input() userInfo: {name: string; photo: string} = {name: '', photo: ''};
    @Output() animationCompleted = new EventEmitter<null>();
    animationData!: any;
    options!: AnimationOptions;
    constructor(private http: HttpClient) {}
    ngOnInit() {
        this.http.get<any>('assets/lotties/race-assets/winner_new.json').subscribe(data => {
            this.userInfo.name =
                this.userInfo.name.length > 15 ? this.userInfo.name.substring(0, 15) + '...' : this.userInfo.name;
            data.layers[5].t.d.k[0].s.t = this.userInfo.name || 'User';
            const isDefault = this.userInfo.photo.includes('defaultMan');
            if (isDefault) {
                data.assets[3].u = 'assets/images/shared/default-photos/';
            }
            data.assets[3].p = !isDefault
                ? this.userInfo.photo
                : this.userInfo.photo.substring(this.userInfo.photo.lastIndexOf('/') + 1);
            this.animationData = data;
            this.options = {
                animationData: this.animationData,
                loop: false,
            };
        });
    }

    onAnimationCompleted() {
        this.animationCompleted.emit();
    }
}
