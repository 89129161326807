import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';
import {CarTypes} from '@shared/components/live-stream/party-feature/race/CarTypes';
import CarColorToName from '@shared/components/live-stream/party-feature/race/CarColorToName';
import {NgClass} from '@angular/common';

@Component({
    selector: 'app-party-feature-race-car',
    standalone: true,
    imports: [LottieComponent, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './car.component.html',
    styleUrl: './car.component.scss',
})
export class CarComponent implements OnInit, OnChanges {
    @Input() carColor!: CarTypes;
    @Input() positions!: {xPosition: number; yPosition: number};
    @Input() leftOffset!: number;
    options: AnimationOptions = {
        path: '',
    };
    borderColor!: string;
    @Input() userPhoto!: string;
    @Input() userCoins!: number;

    constructor() {}

    ngOnInit() {
        this.updateOptions();
        this.convertCarColorToBorder();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('carColor' in changes) {
            this.updateOptions();
            this.convertCarColorToBorder();
        }
    }

    convertCarColorToBorder() {
        switch (this.carColor) {
            case CarTypes.GREEN:
                this.borderColor = '#01D241';
                break;
            case CarTypes.RED:
                this.borderColor = '#FF0000';
                break;
            case CarTypes.BLUE:
                this.borderColor = '#0048FF';
                break;
            case CarTypes.YELLOW:
                this.borderColor = '#FFD800';
                break;
            case CarTypes.GHOST:
                this.borderColor = '#000000';
                break;
        }
    }

    updateOptions() {
        this.options = {
            path: `assets/lotties/race-assets/${CarColorToName(this.carColor)}.json`,
        };
    }

    onAnimationCreated() {}

    protected readonly CarTypes = CarTypes;
}
