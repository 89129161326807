<div class="relative w-full">
    <div *ngIf="!raceFinished" #roadContainer class="relative max-w-full">
        <app-party-feature-race-road />
        <app-party-feature-race-car
            [positions]="{xPosition: data.xPosition || 0, yPosition: data.yPosition || 0}"
            [carColor]="data.cId"
            [leftOffset]="this.xOffset / 2"
            [userPhoto]="data.userPhoto || ''"
            [userCoins]="data.p || 0"
            *ngFor="let data of raceData; trackBy: trackById"
        ></app-party-feature-race-car>
        <div
            class="absolute right-4 top-20 bg-black opacity-25 flex items-center justify-center rounded-[126px] select-none px-2 py-1"
        >
            <p class="text-[10px] text-[#ffffff] text-opacity-100">{{ timerText }}</p>
        </div>
    </div>

    <app-party-feature-race-winner
        (animationCompleted)="onWinnerAnimationCompleted()"
        *ngIf="showWinner"
        [userInfo]="winner"
    ></app-party-feature-race-winner>

    <div class="drawAnimationContainer h-full w-full left-0 top-0 absolute z-50" *ngIf="showDraw"></div>
</div>
