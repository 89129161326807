import { Component, Input } from '@angular/core';
import { ModalCloseButtonComponent } from "../../../core/components/ui-components/modal-close-button/modal-close-button.component";
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
    selector: 'app-alert-modal',
    standalone: true,
    templateUrl: './alert-modal.component.html',
    styleUrl: './alert-modal.component.scss',
    imports: [ModalCloseButtonComponent, TranslocoPipe]
})
export class AlertModalComponent {
    @Input() title!:string;
    @Input() description!:string;

}
