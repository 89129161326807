import {Component, inject} from '@angular/core';
import convertCoin from '@core/utils/helpers/coinConverter';
import {FollowButtonComponent} from '@shared/components/live-stream/follow-button/follow-button.component';
import {CommonResponse} from '@core/models/common/common-response';
import {UserService} from '@core/services/user/user.service';
import {UserDetail} from '@core/models/user/user-detail';
import {Room} from '@core/models/candidate/room';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-host-profile',
    standalone: true,
    imports: [FollowButtonComponent, NgIf],
    templateUrl: './host-profile.component.html',
    styleUrl: './host-profile.component.scss',
})
export class HostProfileComponent {
    protected readonly convertCoin = convertCoin;
    userService = inject(UserService);
    host!: Room;

    followStreamer(userId: number) {
        this.userService
            .followUser(userId, {fullName: this.host.fullName, photo: this.host.photo})
            .subscribe((data: CommonResponse) => {
                if (data.success) {
                    this.host.isFollowing = true;
                }
            });
    }
}
