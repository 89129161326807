import {Component, inject} from '@angular/core';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {Router} from '@angular/router';
import {ModalService} from '@core/services/modal/modal.service';

@Component({
    selector: 'app-kicked-user',
    standalone: true,
    imports: [ModalCloseButtonComponent],
    templateUrl: './kicked-user.component.html',
    styleUrl: './kicked-user.component.scss',
})
export class KickedUserComponent {
    router = inject(Router);
    constructor(private modalService: ModalService) {}

    routeToHome() {
        this.router.navigate(['/live']);
        this.modalService.close();
    }
}
